<template>
  <div>
    <div class="product-box">
      <div class="product-detail">
        <div class="product-img">
          <div class="view-box">
            <div :style="{ left: -activeImage * 400 + 'rem' }">
              <img v-for="(img, index) in productData.picList" :src="img" :key="index" />
            </div>
          </div>
          <div class="select-box">
            <div v-if="productData.picList?.length > 3" class="prev" @click="handleChange(-1)"></div>
            <div v-for="(img, index) in productData.picList" :key="index"
              :class="activeImage == index ? 'active-img' : ''">
              <img :src="img" @click="activeImgEvent(index)" />
            </div>
            <div v-if="productData.picList?.length > 3" class="next" @click="handleChange(1)"></div>
          </div>
        </div>
        <div class="product-text">
          <div>
            <div class="product-name">{{ productData.productName }}</div>
            <div v-for="(e, i) in productData.productParameters" :key="i">
              <div class="title">{{ i }}</div>
              <div class="content">{{ e }}</div>
            </div>
          </div>
          <div class="product-consultation" @click.stop="showPhone = true">产品咨询</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showPhone" class="product-consultation-model" @click.stop="showPhone = false">
    <div @click="null">
      <div>
        <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/phone-red.png" />
        <span>{{ productData.contactInfo }}</span>
      </div>
      <div>详情请拨打咨询热线</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { getProductById } from '@/api'
const showPhone = ref(false)
const productData: any = ref({})
const activeImage = ref(0)

const activeImgEvent = (index: number) => {
  activeImage.value = index
}

const handleChange = (val: number) => {
  if ((activeImage.value == 0 && val < 0) || (activeImage.value == productData.value.picList.length - 1 && val > 0)) return
  activeImage.value += val
}

onMounted(() => {
  const path: any = JSON.parse(window.sessionStorage.getItem('path') || '{}');
  getProductById({ id: path.query?.id }).then((res: any) => {
    productData.value = { ...res };
    productData.value.productParameters = JSON.parse(res.productParameters || "{}")
  })
})
</script>
<style scoped lang="less">
.page-list {
  height: 40rem;
  background: #F7F7F7;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14rem;
  color: #8E8E8E;

  .list-content {
    width: calc(843rem + 17vw);
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;

    img {
      margin: 0 13rem;
      width: 8rem;
      height: 11rem;
    }

    .active {
      font-weight: 600;
      font-size: 14rem;
      color: #D81417;
    }
  }

  .list-space {
    width: 684rem;
    margin-left: 18vw;
  }
}

.product-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 119rem;
  margin-bottom: 100rem;
  font-family: PingFangSC-Regular;

  .product-detail {
    padding-left: 47rem;
    width: calc(843rem + 17vw);
    height: 500rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-img {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .view-box {
        width: 400rem;
        height: 400rem;
        position: relative;
        margin-bottom: 20rem;
        overflow: hidden;

        &>div {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // transition: all 0.5s;
        }

        img {
          width: 400rem;
          height: 400rem;
          border-radius: 8rem;
        }
      }

      .select-box {
        position: relative;
        font-size: 0;
        width: 368rem;
        height: 72rem;
        padding: 0 25rem;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 72rem;
          height: 72rem;
          cursor: pointer;
        }

        &>div {
          border-radius: 4px;
          overflow: hidden;
          border: 1px solid transparent;
        }

        .active-img {
          border-color: #D81417;
        }

        .prev {
          position: absolute;
          left: 3rem;
          top: 50%;
          width: 9rem;
          height: 16rem;
          margin-top: -8rem;
          background: url(https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/left-red.png);
          background-size: 100% 100%;
          cursor: pointer;
          border: none;
        }

        .next {
          position: absolute;
          right: 3rem;
          top: 50%;
          width: 9rem;
          height: 16rem;
          margin-top: -8rem;
          background: url(https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/right-red.png);
          background-size: 100% 100%;
          cursor: pointer;
          border: none;
        }
      }
    }

    .product-text {
      position: relative;
      width: 662rem;
      height: 492rem;
      padding-bottom: 92rem;
      box-sizing: border-box;
      text-align: left;
      font-family: PingFangSC-Medium;

      .product-name {
        font-weight: 600;
        font-size: 26rem;
        color: #333333;
        line-height: 37rem;
        margin-bottom: 10rem;
      }

      .title {
        font-weight: 600;
        font-size: 18rem;
        color: #D71819;
        line-height: 25rem;
        margin: 20rem 0 10rem;
      }

      .content {
        font-weight: bold;
        font-size: 14rem;
        color: #333333;
        line-height: 30rem;
      }

      &>div:first-child {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
      }

      .product-consultation {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 307rem;
        height: 50rem;
        line-height: 50rem;
        background-color: #D81417;
        font-weight: 400;
        font-size: 20rem;
        color: #FFFFFF;
        border-radius: 25px;
        backdrop-filter: blur(4px);
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.product-consultation-model {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: PingFangSC-Regular;

  &>div {
    width: 450rem;
    height: 160rem;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    backdrop-filter: blur(4px);
    padding: 40rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 22px;
    color: #FFFFFF;

    img {
      width: 30rem;
      height: 30rem;
      vertical-align: middle;
      margin-right: 10rem;
    }

    span {
      vertical-align: middle;
    }
  }
}
</style>