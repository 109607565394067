import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import ProductServices from "@/views/ProductServices.vue";
import AboutUs from "@/views/AboutUs.vue";
import AboutUsDetail from "@/views/AboutUsDetail.vue";
import AboutUsView from "@/views/AboutUsView.vue";
import ProductDetail from "@/views/ProductDetail.vue";
import CollaborativeView from "@/views/CollaborativeView.vue";
import ChannelExpansion from "@/views/ChannelExpansion.vue";
import SuccessCases from "@/views/SuccessCases.vue";
import SolutionView from "@/views/SolutionView.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import NewsDetail from "@/views/NewsDetail.vue";
import JobDetail from "@/views/JobDetail.vue";
import CaseDetail from "@/views/CaseDetail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    alias: "/",
    component: HomeView,
    meta: {
      hideTitle: true,
    },
  },
  {
    path: "/about_us_view",
    name: "about_us_view",
    component: AboutUsView,
  },
  {
    path: "/about_us",
    name: "about_us",
    component: AboutUs,
  },
  {
    path: "/about_detail",
    name: "about_detail",
    component: AboutUsDetail,
  },
  {
    path: "/product_service",
    name: "product_service",
    component: ProductServices,
  },
  {
    path: "/product_detail",
    name: "product_detail",
    component: ProductDetail,
  },
  {
    path: "/collaboratives",
    name: "collaboratives",
    component: CollaborativeView,
  },
  {
    path: "/channel_expansion",
    name: "channel_expansion",
    component: ChannelExpansion,
  },
  {
    path: "/success_cases",
    name: "success_cases",
    component: SuccessCases,
  },
  {
    path: "/solution",
    name: "solution",
    component: SolutionView,
  },
  {
    path: "/news_detail",
    name: "news_detail",
    component: NewsDetail,
  },
  {
    path: "/job_detail",
    name: "job_detail",
    component: JobDetail,
  },
  {
    path: "/case_detail",
    name: "case_detail",
    component: CaseDetail,
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    component: PrivacyPolicy
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
