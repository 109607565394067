<template>
  <div class="contact-us">
    <div :class="showContact">
      <div>
        <div>咨询电话</div>
        <div>021-54107208</div>
      </div>
      <div>
        <div style="color: #333;">咨询表单</div>
        <div style="cursor: pointer;" @click="showModel = true">点击填写</div>
      </div>
    </div>
    <div>
      <div @click="showContact = (showContact ? '' : 'show-contact')">联系我们</div>
      <div @click="toTop">
        <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/top.png" />
      </div>
    </div>
  </div>
  <div class="contact-model" v-if="showModel">
    <div class="form-content" @click.stop="null">
      <div class="close" @click.stop="showModel = false"></div>
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" require-asterisk-position="right">
        <el-form-item label="伙伴类型" prop="consultType">
          <el-select v-model="ruleForm.consultType" placeholder="请选择资源类型" class="select-default"
            popper-class="select-option" :suffix-icon="DropDown">
            <el-option v-for="(item, index) in data" :key="index" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item style="margin-top: 20rem;" label="姓名" prop="name">
          <el-input class="form-input" v-model="ruleForm.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName">
          <el-input class="form-input" v-model="ruleForm.companyName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input class="form-input" v-model="ruleForm.phone" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="留言" prop="message">
          <el-input class="form-input" type="textarea" v-model="ruleForm.message" placeholder="请输入" resize="none" />
        </el-form-item>
        <el-form-item class="form-submit">
          <div class="btn-primary" @click="submitForm(ruleFormRef)">
            提交
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, reactive } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { addConsult } from '@/api'
import { ElMessage } from "element-plus";
import DropDown from '@/components/DropDown.vue'

const data = ref([
  {
    name: '硬件品牌方',
    value: 1
  },
  {
    name: '渠道端/零售终端',
    value: 2
  },
  {
    name: 'B/G端需求方',
    value: 3
  },
  {
    name: '其它',
    value: 4
  },
])
interface RuleForm {
  name: string
  companyName: string
  phone: string
  message: string
  consultType: any
}
const ruleFormRef = ref<FormInstance>()
const ruleForm: any = reactive<RuleForm>({
  name: '',
  companyName: '',
  phone: '',
  message: '',
  consultType: null
})

const rules = reactive<FormRules<RuleForm>>({
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' }
  ],
  companyName: [
    { required: true, message: '请输入公司名', trigger: 'blur', }
  ],
  phone: [{ required: true, message: '请输入电话号码', trigger: 'blur', }],
  consultType: [{ required: true, message: '请选择伙伴类型', trigger: 'blur', }],
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      addConsult({ ...ruleForm, appId: 1 }).then((res) => {
        ElMessage({
          message: '感谢您的反馈!',
          type: "success",
          duration: 2000,
        })
        showModel.value = false
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

const showContact = ref('')
const showModel = ref(false)
const toTop = () => {
  window.scrollTo(0, 0)
}
</script>
<style scoped lang="less">
.contact-us {
  position: fixed;
  right: 0;
  top: 559rem;
  width: 233rem;
  height: 186rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: PingFangSC-Medium;

  &>div:first-child {
    position: absolute;
    right: -200rem;
    width: 172rem;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 13rem 0 rgba(214, 222, 251, 0.67);
    border-radius: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 11rem 0;
    box-sizing: border-box;
    transition: all .5s;

    &>div {
      width: 100%;
      height: 77rem;
      padding-left: 32rem;
      padding-top: 16rem;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 16rem;
      color: #DD3939;
      line-height: 22rem;
      text-align: left;
    }

    &>div:first-child {
      background: #F9FAFF;
    }
  }

  .show-contact {
    right: 61rem !important;
  }

  &>div:last-child {
    width: 48rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &>div:first-child {
      height: 118rem;
      text-align: center;
      padding: 19rem 14rem 20rem 13rem;
      box-sizing: border-box;
      background: linear-gradient(90deg, #CD8B8C 0%, #D71819 100%);
      border-radius: 4px;
      opacity: 0.86;
      font-weight: 500;
      font-size: 14rem;
      color: #FFFFFF;
      cursor: pointer;
    }

    &>div:last-child {
      width: 48rem;
      height: 58rem;
      background: #FFFFFF;
      box-shadow: 0 0 13rem 0 #D6DEFB;
      border-radius: 2rem;
      cursor: pointer;
      display: flex;
      justify-content: space-around;
      align-items: center;

      img {
        width: 16rem;
        height: 21rem;
      }
    }
  }
}

.contact-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: rgba(0, 0, 0, 0.3);
  // margin-top: -60rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &>div {
    width: 578rem;
    height: 766rem;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8rem;
    backdrop-filter: blur(4rem);
  }

  .form-content {
    position: relative;
    padding: 60rem;
    box-sizing: border-box;

    .close {
      position: absolute;
      right: 30rem;
      top: 30rem;
      width: 26rem;
      height: 26rem;
      background: url(https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/icon/close-red2.png);
      background-size: 100% 100%;
      cursor: pointer;
    }

    .el-input__wrapper {
      width: 458rem;
    }
  }
}
</style>