<template>
  <div>
    <div class="banner"></div>
    <div class="about-us">
      <div>
        <div class="title">加入我们</div>
        <div class="job-details">
          <div v-for="(item, index) in jobDetails" :key="index"
            @click="toOtherPath(`/job_detail?id=${item.id}&pathName=关于我们,加入我们,岗位详情`, ['关于我们', '加入我们', '岗位详情'])">
            <div class="text-title">{{ item.name }}</div>
            <div>{{ item.introduction }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'

const store = useStore()
const jobDetails: any = ref([])
const router = useRouter()

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(() => {
  selectCommonElement({ columnId: 7 }).then((res: any) => {
    jobDetails.value = [...res]
  })
  if (document.documentElement && document.documentElement.scrollTop) {
    document.documentElement.scrollTop = 0;
  } else if (document.body) {
    document.body.scrollTop = 0
  }
})
</script>
<style scoped lang="less">
.about-us {
  margin-top: -60rem;
  padding: 24rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PingFangSC-Regular;
  text-align: left;

  &>div {
    width: calc(843rem + 17vw);

    .title {
      font-weight: bold;
      font-size: 30rem;
      color: #333333;
      margin-bottom: 34rem;
    }

    .job-details {
      width: 100%;
      font-size: 0;
      margin-bottom: 66rem;

      &>div {
        width: calc(50% - 5rem);
        height: 193rem;
        display: inline-block;
        margin-bottom: 10rem;
        vertical-align: top;
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 30rem;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 14rem;
        color: #333333;

        .text-title {
          font-weight: 600;
          font-size: 24rem;
          color: #0A0A0A;
          text-shadow: 0px 0px 12px rgba(215, 24, 25, 0.2);
          margin-bottom: 10rem;
        }

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(215, 24, 25, 0.2), 0px 0px 4px 0px rgba(215, 24, 25, 0.2);
        }
      }

      &>div:nth-child(even) {
        margin-left: 10rem;
        margin-bottom: 0;
      }
    }
  }
}
</style>