<template>
  <div class="medical-treatment">
    <div class="title-font-style">医疗解决方案</div>
    <div class="illustrate">
      <div>利用元宇宙/VR技术实现医术培训、康复治疗、病理研究和预防知识普及、病情评估等，并能为不同地区提供高水平医疗服务，减少就医成本和时间，改善医疗资源分配和利用，对整个医疗行业产生积极变革。</div>
    </div>
    <div class="subtitle">行业痛点</div>
    <div class="fzqj-box">
      <div class="fzqj">
        <div v-for="(item, index) in prospects" :key="index">
          <img :src="item.url" />
          <div>
            <div class="title">
              <img :src="item.src" />
              <span>{{ item.title }}</span>
            </div>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <div>
        <img class="l1" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/jiangben.png" />
        <div class="l2">降低成本</div>
        <div class="l3">基于VR设备配合医学培训、康复治疗、医疗研究等，能高效打通信息共享的途径，降低成本。</div>
      </div>
      <div>
        <img class="l1" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/zengxiao.png" />
        <div class="l2" style="margin-top: 12rem;">提升效率</div>
        <div class="l3">VR医疗方案可以让医学生近距离接触及学习不同类型的医疗模型，在医疗培训过程中以第一视角观察操作步骤，也能让康复患者跟随VR系统指令复健，显著提升效率。</div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <div>
          <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-img8.png" class="left-radius"/>
          <div>
            <div class="title">
              <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-icon8.png" />
              <span>医疗培训</span>
            </div>
            <div class="content">交互式培训模拟，随时复盘教学。</div>
          </div>
        </div>
        <div>
          <div>
            <div class="title">
              <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-icon9.png" class="right-radius" />
              <span>康复治疗</span>
            </div>
            <div class="content">可视化康复指导，协同康复，高效精准。</div>
          </div>
          <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-img9.png" />
        </div>
        <div>
          <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-img10.png" class="left-radius"/>
          <div>
            <div class="title">
              <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-icon10.png" />
              <span>医学科普</span>
            </div>
            <div class="content">演示病理特性，传播预防知识。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div @click="toOtherPath(`/case_detail?id=${casesList.id}&pathName=解决方案,医疗解决方案,应用案例`, ['解决方案', '医疗解决方案', '应用案例'])">
          <img :src="casesList.ossPath" />
          <div class="content">
            <div class="title">{{ casesList.title }}</div>
            <div>{{ casesList.subTitle }}</div>
            <div class="detail">
              {{ casesList.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()
const casesList: any = ref({})
const prospects = [
  {
    title: '医疗资源分配不均',
    content: '由于医疗资源分配不均，欠发达地区比较难接触到最先进的医学研究成果和预防知识普及。',
    url: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yiliao_f1.png',
    src: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yiliao_f1_icon.png'
  },
  {
    title: '传统康复成本高昂',
    content: '患者接受规范的康复治疗需要专业的设备辅助，对从业人员的专业水平要求较高，成本也较高。',
    url: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yiliao_f2.png',
    src: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yiliao_f2_icon.png'
  },
  {
    title: '传统医疗培训效果欠佳',
    content: '参加医学培训需要承担高昂的线下时间成本和差旅费用，并且在培训中观察的视角也受到限制，影响培训效果。',
    url: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yiliao_f3.png',
    src: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yiliao_f3_icon.png'
  }
]

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(() => {
  selectCommonElement({ columnId: 4 }).then((res: any) => {
    casesList.value = [...res].filter(e => e.industry == 4)[0] || {}
  })
})
</script>
<style scoped lang="less">
.medical-treatment {
  background-color: #F7F7F7;
  padding-top: 100rem;
  overflow: hidden;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40rem 0 80rem;

    &>div {
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      width: 889rem;
      line-height: 30rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 28rem;
    color: #333333;
    line-height: 40rem;
    display: inline-block;
    padding: 0 10rem;

    &::after {
      content: '';
      width: 100%;
      height: 13rem;
      background: rgba(215, 24, 25, 0.21);
      position: absolute;
      left: 0;
      bottom: 3rem;
    }
  }

  .fzqj-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 60rem 0 80rem;

    .fzqj {
      width: 1203rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &>div {
        width: 393rem;
        font-size: 0;
        box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 8rem;
        overflow: hidden;

        &>img {
          width: 393rem;
          height: 330rem;
        }

        &>div {
          padding: 30rem 29rem;
          font-weight: 400;
          font-size: 16rem;
          color: #5C5C5C;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 200rem;
          box-sizing: border-box;
          background: #FFFFFF;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
              margin-right: 5rem;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .fays {
    width: 100%;
    height: 530rem;
    background: url(https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/fays-bg.png);
    background-size: 100% 100%;
    margin-top: 80rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100rem;

    &>div {
      margin: 0 5rem;
      width: 595rem;
      height: 330rem;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 8rem;
      backdrop-filter: blur(10rem);
      padding: 43rem 65rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .l1 {
        width: 124rem;
        height: 114;
      }

      .l2 {
        font-weight: 600;
        font-size: 22rem;
        color: #333333;
        line-height: 30rem;
        font-family: PingFangSC-Medium;
        margin-top: 30rem;
        margin-bottom: 10rem;
      }

      .l3 {
        font-weight: 400;
        font-size: 16rem;
        color: #333333;
        line-height: 30rem;
      }
    }
  }

  .yycj {
    background-color: #fff;
    padding-top: 60rem;

    .yycj-content {
      margin-top: 80rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &>div {
        width: 1201rem;
        background: #F7F7F7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8rem;
        margin-bottom: 10rem;

        &>img {
          width: 978rem;
          height: 280rem;
        }

        &>div {
          display: inline-block;
          width: 222rem;
          height: 280rem;
          text-align: left;
          padding: 60rem 22rem;
          box-sizing: border-box;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30rem;
            margin-bottom: 10rem;

            img {
              width: 50rem;
              height: 50rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }

          .content {
            padding: 8rem;
            color: #333333;
            font-size: 16rem;
            line-height: 30rem;
            box-sizing: border-box;
            text-align: justify;
          }
        }
      }

      &>div:last-child {
        margin-bottom: 0;
      }
    }
  }

  .yyal {
    padding-top: 80rem;
    background-color: #fff;
    overflow: hidden;

    .yyal-content {
      margin: 80rem 0 40rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 1040rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60rem;
        padding: 20rem;
        border-radius: 8rem;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(215, 24, 25, 0.2), 0px 0px 4px 0px rgba(215, 24, 25, 0.2);
        }

        img {
          width: 400rem;
          height: 224rem;
        }

        .content {
          width: 607rem;
          height: 224rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 24rem;
            color: #0A0A0A;
            line-height: 33px;
            margin: 8rem 0 9rem;
          }

          .detail {
            font-weight: 400;
            font-size: 14rem;
            color: #333333;
            line-height: 30rem;
            margin-top: 20rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }
  }
}
</style>