<template>
  <div>
    <div class="banner"></div>
    <div class="xxqd">
      <div class="title-font-style">线下渠道</div>
      <div class="text">
        <div class="p1">
          <span>年平均增长</span>
          <span class="keynote">59%</span>
          <span> 遍布数百城市 </span>
          <span class="keynote">4000+</span>
          <span> 渠道</span>
        </div>
        <div class="p2">
          <span>覆盖 </span>
          <span class="keynote">华东、华南、华北、西南 </span>
          <span>等消费活跃城市，同时全国渠道网络正在加速建立</span>
        </div>
        <div class="p2">
          <span>2016年以来销售额平均年增长率</span>
          <span class="keynote"> 59%</span>
          <span>，凝丰智能渠道销售额占行业整体销售额</span>
          <span class="keynote"> 60%+</span>
        </div>
      </div>
      <div class="channel-img">
        <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/channel1.png" />
      </div>
    </div>
    <div class="xsqd">
      <div class="title-font-style">线上渠道</div>
      <div class="text">
        <div class="p1">
          <span>覆盖 </span>
          <span class="keynote">10+</span>
          <span> 平台 引流 </span>
          <span class="keynote">百万</span>
          <span> 流量</span>
        </div>
        <div class="p2">
          <span>构建完善的线上流量平台矩阵，与线下渠道形成互补优势，在线销售量常年位居平台销量前列 </span>
        </div>
      </div>
      <div class="channel-img">
        <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/channel2.png" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
<style scoped lang="less">
.xxqd,
.xsqd {
  margin-top: -60rem;
  padding-top: 100rem;
  background-color: #fff;
  font-size: 0;

  .text {
    margin-top: 40rem;

    .p1 {
      font-weight: 400;
      font-size: 20rem;
      color: #333333;
      margin-bottom: 20rem;

      &>span {
        vertical-align: middle;
      }

      .keynote {
        position: relative;
        font-size: 30rem;
        color: #D71819;
        font-weight: 800;
        font-family: PingFangSC-Medium;
        padding: 0 4rem;

        &::after {
          content: '';
          height: 13rem;
          width: 100%;
          background: rgba(215, 24, 25, 0.21);
          position: absolute;
          left: 0;
          bottom: 3rem;
        }
      }
    }

    .p2 {
      font-weight: 400;
      font-size: 16rem;
      color: #333333;

      .keynote {
        font-weight: bold;
        color: #D71819;
      }
    }
  }

  .channel-img {
    width: 1200rem;
    height: 462rem;
    display: inline-block;
    margin-top: 40rem;
    overflow: hidden;
    margin-bottom: 100rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.xsqd {
  margin-top: 0;
  background-color: #F7F7F7;
  margin-bottom: 100rem;
}
</style>