export const findSequences = (text: string) => {
  const regex = /webstaticvideo.*?mp4/gi;
  const matches = text.matchAll(regex);

  // 将匹配结果转换为数组
  const sequences = Array.from(matches, (match) => match[0]);

  return sequences;
};

export const findSequences2 = (text: string) => {
  const regex = /source src=".*?"/gi;
  const matches = text.matchAll(regex);

  // 将匹配结果转换为数组
  const sequences = Array.from(matches, (match) => match[0].slice(0, -1));

  return sequences;
};
