// 解决方案枚举
export const solutions = [
  {
    name: "文娱",
    content:
      "全景景区导览、虚拟博物馆、虚实融合AR项目应用、商场儿童游乐项目体验、企业展厅、营销展示、虚拟数字人、虚拟直播",
    url: "https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/img2.png",
  },
  {
    name: "工业",
    content:
      "设计评审、虚拟装配、维修维护、远程协助、技能培训、数字孪生、点巡检、工厂漫游",
    url: "https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/img1.png",
  },
  {
    name: "教育",
    content:
      "基础教育/高等教育/职业教育教学、播控、创新实验室建设、创新课堂建设、企业培训",
    url: "https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/img3.png",
  },
  {
    name: "医疗",
    content: "医疗培训、康复治疗、医学科普",
    url: "https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/img4.png",
  },
  {
    name: "军警部队",
    content: "虚拟战场演习、武器装备操作训练、灾情救援仿真模拟",
    url: "https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/img5.png",
  },
];


export const pathTable: any = {
  home: 0,
  product_service: 1,
  channel_expansion: 1,
  product_detail: 1,
  solution: 2,
  success_cases: 3,
  case_detail: 3,
  collaboratives: 4,
  about_us_view: 5,
  news_detail: 5,
  about_us: 6,
  about_detail: 7
}

export const pageRoute: any = {
  '数智产品': '/product_service?pathName=产品服务,数智产品',
  '文娱解决方案': '/solution?type=0&pathName=解决方案,文娱解决方案',
  '工业解决方案': '/solution?type=1&pathName=解决方案,工业解决方案',
  '教育解决方案': '/solution?type=2&pathName=解决方案,教育解决方案',
  '医疗解决方案': '/solution?type=3&pathName=解决方案,医疗解决方案',
  '军警部队解决方案': '/solution?type=4&pathName=解决方案,军警部队解决方案',
  '解决方案': '/solution?type=0&pathName=解决方案,文娱解决方案',
  '成功案例': '/success_cases',
  '新闻动态': '/about_us?pathName=关于我们,新闻动态',
  '加入我们': '/about_detail?pathName=关于我们,加入我们',
  '关于我们': '/about_us_view?pathName=关于我们,公司介绍',
  '应用案例': '/success_cases'
}