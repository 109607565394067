<template>
  <div class="privacy-policy">
    <div class="content">
      <h3>法律声明及隐私政策</h3>
      <p>
        您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴此，平台服务提供者（或简称“我们”）制定本《法律声明及隐私政策》（以下称“本隐私政策”或“本政策”），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地做出适当的选择。我在此温馨提示您：
      </p>
      <p>本政策适用于本网站产品或服务。</p>
      <p>在使用本网站各项产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用平台各项产品或服务，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过客服电话与我们联系。</p>
      <p>您同意隐私政策表示您已了解应用提供的功能，以及功能运行所需的必要个人信息，并给予相应的收集使用授权，但并不代表您已单独同意开启附加功能、处理非必要个人信息，相关附加功能的开启、处理非必要个人信息以及处理敏感个人信息，我们会根据您的实际使用情况单独征求您的同意。</p>
      <p class="weight700">第一部分 定义及适用范围</p> 
      <p>一、定义</p>
      <p>平台：是指域名为“https://nfznxr.com”的网站。</p>
      <p>平台服务提供者：指平台互联网信息服务提供者“上海凝丰智能科技有限公司”。</p>
      <p>个人信息：指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</p>
      <p>个人敏感信息：敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹信息，以及不满十四周岁未成年人的个人信息。指包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等的个人信息。</p>
      <p>个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</p>
      <p>儿童：指不满十四周岁的未成年人。</p>
      <p>设备信息：包括设备标识符（IMEI、IDFA、Android ID、MAC、OAID、IMSI及其他设备相关信息）、应用信息（应用崩溃信息、通知开关状态、应用安装列表及其他应用相关信息）、设备参数及系统信息（设备类型、设备型号、操作系统及硬件相关信息），设备网络环境信息（IP地址，WiFi信息，基站信息及其他网络相关信息），以产品实际采集情况为准。</p>
      <p>服务日志信息：包括浏览记录、点击查看记录、搜索查询记录、关注分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间及您访问的网页记录。</p>
      <p>去标识化：指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。</p>
      <p>匿名化：指个人信息经过处理无法识别特定自然人且不能复原的过程。</p>
      <p>二、适用范围</p>
      <p>本政策适用于平台服务提供者以平台网站以及随技术发展出现的新形态向您提供的各项产品和服务。</p>
      <p class="weight700">第二部分 法律声明</p> 
      <p>一、权利归属</p>
      <p>除非我们另行声明，平台内的所有产品、技术、软件、程序、数据及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计等）的所有权利（包括版权、商标权、专利权、商业秘密及其他相关权利）均归平台服务提供者及/或其关联公司所有。未经平台服务提供者及/或其关联公司许可，任何人不得以包括通过机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载等方式擅自使用平台内的任何内容。</p>
      <p>平台的文字及/或标识，以及平台的其他标识、徽记、产品和服务名称均为平台服务提供者及/或其关联公司在中国和其他国家的商标，如有宣传、展示等任何使用需要，您必须取得平台服务提供者及/或其关联公司事先书面授权。</p>
      <p>二、责任限制</p>
      <p>我们在此提示，您在使用我们服务期间应当遵守中华人民共和国的法律法规，不得危害网络安全，不得利用我们的服务从事侵犯他人名誉、隐私、知识产权和其他合法权益的活动。尽管有前述提示，我们不对您使用我们服务的用途和目的承担任何责任。</p>
      <p>三、知识产权保护</p>
      <p>我们尊重知识产权，反对并打击侵犯知识产权的行为。知识产权权利人若认为平台内容（包括但不限于平台用户发布的信息）侵犯其合法权益的，可以通过平台进行投诉，我们将在收到知识产权权利人合格通知后依据相应的法律法规以及平台规则及时处理。</p>
      <p class="weight700">第三部分：隐私政策</p> 
      <p>本隐私政策部分将帮助您了解以下内容：</p>
      <p>一、信息收集及使用</p>
      <p>二、对外提供信息</p>
      <p>三、信息的存储</p>
      <p>四、您的权利</p>
      <p>五、未成年人保护</p>
      <p>六、本政策如何更新</p>
      <p>七、如何联系我们</p>
      <p>一、信息收集及使用</p>
      <p>１、在您使用我们的产品/服务时，我们需要收集和使用的您的个人信息</p>
      <p>为了向您更好的介绍我们产品或服务，您需要提供必要的联系信息（姓名、公司名称、联系电话）。如您拒绝提供相应信息，您将无法及时收到我们咨询服务。</p>
      <p>2、客服及争议处理</p>
      <p>当您与我们联系或提出纠纷处理申请时， 为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。</p>
      <p>为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。</p>
      <p>3、Cookie和同类技术的服务</p>
      <p>为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储相关信息：这些信息可能是Cookie、Flash Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。</p>
      <p>请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝我们的Cookie，但拒绝我们的Cookie在某些情况下可能会影响您安全访问网站和使用我们提供的服务。</p>
      <p>如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。但如果您这么做，在某些情况下可能会影响您安全访问我们的网站，且可能需要在每一次访问我们的网站时更改用户设置。
二、对外提供信息</p>
      <p>（一）共享</p>
      <p>我们不会与平台服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：</p>
      <p>1、履行法定义务所必需的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</p>
      <p>2、取得您同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
      <p>请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您更加谨慎地考虑并做出决定。</p>
      <p>（二）转让</p>
      <p>在平台服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，我们会向您告知接收方的名称或者姓名和联系方式。接收方将继续履行本政策及其他法定义务。接收方变更原先的处理目的、处理方式的，会重新取得您的同意。</p>
      <p>（三）公开披露</p>
      <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
      <p>1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；</p>
      <p>2、如果我们确定您出现违反法律法规或严重违反平台相关协议规则的情况，或为保护平台及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或平台相关协议、规则披露关于您的个人信息，包括相关违规行为以及平台已对您采取的措施。</p>
      <p>三、信息的存储</p>
      <p>（一）存储地点</p>
      <p>我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中国境内。以下情形下，我们会在履行了法律规定的义务后，向境外实体提供您的个人信息：</p>
      <p>1、适用的法律有明确规定；</p>
      <p>2、获得您的明确单独同意；</p>
      <p>针对以上情形，我们会通过合同等形式确保以不低于本政策规定的程度保护您的个人信息。</p>
      <p>（二）存储期限</p>
      <p>我们会采取合理可行的措施，尽力避免收集和处理无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求。我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：</p>
      <p>1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询、投诉或诉讼；</p>
      <p>2、保证我们为您提供服务的安全和质量；</p>
      <p>3、您是否同意更长的留存期间；</p>
      <p>4、是否存在保留期限的其他特别约定。</p>
      <p>在超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
      <p>（三）存储安全</p>
      <p>1、数据保护技术措施</p>
      <p>我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。</p>
      <p>2、个人信息安全事件的响应</p>
      <p>如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话、推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
      <p>3、账户安全风险的防范</p>
      <p>在使用服务平台时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。此外，您也可以通过我们的服务与第三方建立沟通、交流信息或分析内容。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供，不轻信通过其他交易工具进行交易，以免信息被窃取甚至遭遇电信网络诈骗。如您担心自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络客服以便我们根据您的申请采取相应措施。</p>
      <p>四、您的权利</p>
      <p>您可以通过以下方式访问及管理您的信息。如果您在管理您的个人信息时遇到问题，您还可以通过本条款所列联系方式与我们联系，我们将在15天内回复您的请求。但也请您理解，我们可能会因为履行法律法规义务或者我们提供服务必要性的原因无法完全满足您的请求。</p>
      <p>（一）查询、更正和补充您的信息</p>
      <p>您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：</p>
      <p>1、登录平台相关产品后台，点击进入账户功能区，查询、管理（变更、删除）使用我们服务时提交的基本信息。您如果需要进一步查询、更正和补充您的账户信息（如个人基本资料信息、添加安全信息或关闭您的账号等），您需联系平台客服进行相关操作。</p>
      <p>2、您可以在平台各产品后台查看您使用我们的产品和服务的相关信息（如您开通的服务、订单信息等）。</p>
      <p>3、如果您无法通过上述路径访问该等个人信息，您可以随时通过客户中心与我们取得联系。对于您在使用我们的产品或服务过程中产生的其他个人信息。</p>
      <p>（二）删除您的个人信息</p>
      <p>您可以通过“（一）查询、更正和补充您的信息”中列明的方式删除您的部分个人信息。</p>
      <p>在以下情形中，您可以通过与客服联系向我们提出删除个人信息的请求：</p>
      <p>1、如果我们处理个人信息的行为违反法律法规；</p>
      <p>2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
      <p>3、如果我们处理个人信息的行为严重违反了与您的约定</p>
      <p>4、除我们违法违约使用您的个人信息外，同时符合以下条件的，您可以提出删除个人信息的请求：</p>
      <p>（1）您未使用平台内其他任一产品或服务的。</p>
      <p>（2）您不再使用任一产品或服务，同时您主动注销了您的账号。</p>
      <p>（3）不存在与您相关的履行中的权利义务、欠款等任何纠纷。</p>
      <p>5、如果我们停止提供产品或者服务，或者保存期限已届满；</p>
      <p>6、若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的</p>
      <p>个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。</p>
      <p>当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</p>
      <p>（三）改变您授权同意的范围</p>
      <p>每个业务功能可能需要一些基本的个人信息才能得以完成。除此之外，对于额外个人信息的收集和使用，您可以在平台相关客户端修改账户设置或改变您的智能移动设备的设置等方式给予或收回您的授权。</p>
      <p>如您无法通过前述途径改变您授权同意的范围，您可以进一步与客服中心联系修改。但请注意，对于部分类型的个人信息，例如实现平台产品或服务基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能没有办法响应您改变授权范围的请求（详见（五）响应您的上述请求）。</p>
      <p>当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
      <p>（四）个人信息主体注销账号</p>
      <p>我们通过平台账户为您提供产品或者服务，如您需要注销您的账号，您可以通过以下方式申请注销您的账户：</p>
      <p>在您主动注销您的账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
      <p>（五）响应您的上述请求</p>
      <p>为保障您的账户安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先要求您验证自己的身份（如增加账户核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。</p>
      <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于与您的身份不直接关联的信息、、无合理理由重复申请的信息，或者无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
      <p>五、未成年人保护</p>
      <p>平台的产品和服务主要面向成人。如果没有父母或监护人的同意，未成年人不得创建自己的用户账号。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</p>
      <p>对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</p>
      <p>六、本政策如何更新</p>
      <p>我们的隐私政策可能变更。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在专门页面上发布对隐私政策所做的任何变更以及旧版本的存档，供您查阅。对于重大变更，我们还会提供更为显著的通知（包括我们会通过平台公示或者站内信的方式进行意见征集、公告通知甚至向您提供弹窗提示）。</p>
      <p>本政策所指的重大变更包括但不限于：</p>
      <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
      <p>2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；</p>
      <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；</p>
      <p>6、个人信息安全影响评估报告表明存在高风险。</p>
      <p>七、如何联系我们</p>
      <p>如果您对本隐私权政策有任何疑问、意见或建议，可以通过以下方式联系我们：</p>
      <p>电子邮件：chenfeng.cao@nfznxr.com</p>
      <p>电 话：021-54107208</p>
      <p>如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</p>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
<style lang="less" scoped>
.privacy-policy {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 1200rem;

  .content {
    width: 100%;
    background-color: #fff;
    height: 90%;
    border-radius: 4px;
    padding: 50px 80px;
    box-sizing: border-box;
    text-align: left;
    line-height: 2;
    font-size: 15px;
    overflow: hidden;
    overflow-y: auto;

    h3 {
      text-align: center;
      margin-bottom: 30px;
    }

    .seg {
      margin: 50px 0;
    }
  }

  .weight700 {
    font-weight: 700;
    margin-top: 56px;
  }

  .color-red {
    color: red;
  }
}
</style>