<template>
  <div>
    <div class="banner"></div>
    <div class="solution-tab">
      <div v-for="(item, index) in solutionList" :key="index" :class="activeSolutionIndex == index ? 'active' : ''"
        @click="handleClickEvent(index, item)">{{ item }}</div>
    </div>
    <div class="solution-content">
      <entertainment-plan v-if="activeSolutionIndex == 0"></entertainment-plan>
      <industry-model v-if="activeSolutionIndex == 1"></industry-model>
      <education-model v-if="activeSolutionIndex == 2"></education-model>
      <medical-treatment v-if="activeSolutionIndex == 3"></medical-treatment>
      <military-police v-if="activeSolutionIndex == 4"></military-police>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue'
import EntertainmentPlan from '@/components/EntertainmentPlan.vue'
import IndustryModel from '@/components/IndustryModel.vue'
import EducationModel from '@/components/EducationModel.vue'
import MedicalTreatment from '@/components/MedicalTreatment.vue'
import MilitaryPolice from '@/components/MilitaryPolice.vue'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()

const solutionList = ['文娱解决方案', '工业解决方案', '教育解决方案', '医疗解决方案', '军警部队解决方案']
const activeSolutionIndex = ref(-1)

const handleClickEvent = (index: number, item: string) => {
  store.state.pageTitle = ['解决方案', item]
  store.state.programmeIndex = index
  router.push(`/solution?type=${index}&pathName=解决方案,${item}`)
}

onMounted(() => {
  const path: any = JSON.parse(window.sessionStorage.getItem('path') || '{}');
  activeSolutionIndex.value = +path.query.type || 0
})

watch(store.state, (newState) => {
  if (newState.programmeIndex != activeSolutionIndex.value) {
    activeSolutionIndex.value = newState.programmeIndex
  }
})
</script>
<style scoped lang="less">
.solution-tab {
  margin-top: -60rem;
  font-weight: 400;
  font-size: 20rem;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60rem;

  &>div {
    margin: 0 30rem;
    padding: 0 10rem;
    cursor: pointer;
    border-bottom: 2rem solid transparent;
    height: 60rem;
    line-height: 60rem;
  }

  .active {
    font-weight: 800;
    font-size: 20rem;
    color: #D81417;

    border-color: #D81417;
  }
}
</style>