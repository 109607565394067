<template>
  <div class="entertainment">
    <div class="title-font-style">文娱解决方案</div>
    <div class="illustrate">
      <div>将元宇宙与内容资源融合创新，应用虚拟现实和新媒体技术打造沉浸式的时空新体验，实现“文化+科技”“文娱+消费”的新业态模式，推动文娱产业升级。</div>
    </div>
    <div class="subtitle">发展前景</div>
    <div class="fzqj-box">
      <div class="fzqj">
        <div v-for="(item, index) in prospects" :key="index">
          <img :src="item.url" />
          <div>
            <div class="title">
              <img :src="item.src" />
              <span>{{ item.title }}</span>
            </div>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <div>
        <img class="l1" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/positioning.png" />
        <div class="l2">空间定位</div>
        <img class="l3" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/triangle.png" />
        <div class="l4">准</div>
        <div class="l5">
          <div>自研空间定位算法</div>
          <div>厘米级视觉定位</div>
          <div>空间导航精准</div>
          <div>支持大空间定位</div>
        </div>
      </div>
      <div>
        <img class="l1" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/arrange.png" />
        <div class="l2">交付部署</div>
        <img class="l3" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/triangle.png" />
        <div class="l4">快</div>
        <div class="l5">
          <div>SaaS化服务</div>
          <div>快速调整内容布局</div>
          <div>2天内完成空间构建</div>
          <div>现场体验式交付</div>
        </div>
      </div>
      <div>
        <img class="l1" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/edit.png" />
        <div class="l2">空间定位</div>
        <img class="l3" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/triangle.png" />
        <div class="l4">简</div>
        <div class="l5">
          <div>3D场景地图</div>
          <div>可视化交互</div>
          <div>“拖拉拽”操作</div>
          <div>所“建”即所得</div>
        </div>
      </div>
      <div>
        <img class="l1" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/application.png" />
        <div class="l2">空间定位</div>
        <img class="l3" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/triangle.png" />
        <div class="l4">多</div>
        <div class="l5">
          <div>AR/MR眼镜</div>
          <div>移动端APP</div>
          <div>小程序AR</div>
          <div>Web</div>
        </div>
      </div>
      <div>
        <img class="l1" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/positioning.png" />
        <div class="l2">内容扩展性</div>
        <img class="l3" src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/triangle.png" />
        <div class="l4">强</div>
        <div class="l5">
          <div>数字多媒体</div>
          <div>动画特效</div>
          <div>手势互动</div>
          <div>3D数字人</div>
        </div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <div>
          <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-img1.png" class="left-radius" />
          <div>
            <div class="title">
              <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-icon1.png" />
              <span>AR沉浸式导览</span>
            </div>
            <div class="content">通过数实融合的⽅式，将博物馆、美术馆等文博场馆及历史风貌街区，商业街区的⼈文历史、民俗风情、历史典故融入在实际场馆及街区环境中，结合数字化互动内容，实现沉浸式文旅体验。
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="title">
              <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-icon2.png" class="right-radius" />
              <span>历史情景演绎</span>
            </div>
            <div class="content">搭建3D场景及数字人，使用动作捕捉设备制作沉浸式的互动场景，实现对历史事件，文物“前世今生”更加真实、生动的演绎效果。</div>
          </div>
          <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/yycj-img2.png" />
        </div>
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div @click="toOtherPath(`/case_detail?id=${casesList.id}&pathName=解决方案,文娱解决方案,应用案例`, ['解决方案', '文娱解决方案', '应用案例'])">
          <img :src="casesList.ossPath" />
          <div class="content">
            <div class="title">{{ casesList.title }}</div>
            <div>{{ casesList.subTitle }}</div>
            <div class="detail">
              {{ casesList.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()
const casesList: any = ref({})
const prospects = [
  {
    title: '消费升级',
    content: '当下，国内新型消费潜力迸发，以沉浸式旅游、文化旅游等为特点的文娱旅游正成为各地积极培育的消费增长点。同时，数实融合不断深化，实体经济数字化、智能化、绿色化转型不断加快，为经济社会发展持续赋能。',
    url: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/fzqj1.png',
    src: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/xiaofei.png'
  },
  {
    title: '政策引领',
    content: '工信部等五部门联合印发《元宇宙产业创新发展三年行动计划（2023-2025年）》，北上广深、南京等数十多个城市纷纷出台元宇宙发展相关政策，希望借势元宇宙的机会加速传统产业数字化转型并大力发展数字经济，同时尝试结合地方特色打造丰富的元宇宙标杆场景和应用。',
    url: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/fzqj2.png',
    src: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/zhengce.png'
  },
  {
    title: '数智未来',
    content: '人类社会数字化、智能化是不可逆的发展趋势，AIGC的应用将会极大的提高人类的生产效率，也将会加快数实融合、数实互促、元宇宙的模态到来。',
    url: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/fzqj3.png',
    src: 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/shuzhi.png'
  }
]

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(() => {
  selectCommonElement({ columnId: 4 }).then((res: any) => {
    casesList.value = [...res].filter(e => e.industry == 1)[0] || {}
  })
})

</script>
<style scoped lang="less">
.entertainment {
  background-color: #F7F7F7;
  padding-top: 100rem;
  overflow: hidden;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40rem 0 80rem;

    &>div {
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      width: 661rem;
      line-height: 30rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 28rem;
    color: #333333;
    line-height: 40rem;
    display: inline-block;
    padding: 0 10rem;

    &::after {
      content: '';
      width: 100%;
      height: 13rem;
      background: rgba(215, 24, 25, 0.21);
      position: absolute;
      left: 0;
      bottom: 3rem;
    }
  }

  .fzqj-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 60rem 0 80rem;

    .fzqj {
      width: 1206rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &>div {
        width: 394rem;
        font-size: 0;
        box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 8rem;
        overflow: hidden;

        &>img {
          width: 394rem;
          height: 330rem;
        }

        &>div {
          padding: 30rem 29rem;
          font-weight: 400;
          font-size: 16rem;
          color: #5C5C5C;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 290rem;
          box-sizing: border-box;
          background: #FFFFFF;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .fays {
    width: 100%;
    height: 530rem;
    background: url(https://njyjxr.oss-cn-shanghai.aliyuncs.com/nfznxr-images/jjfa/fays-bg.png);
    background-size: 100% 100%;
    margin-top: 80rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100rem;

    &>div {
      margin: 0 5rem;
      width: 232rem;
      height: 410rem;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 8rem;
      padding: 20rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .l1 {
        width: 80rem;
        height: 93rem;
      }

      .l2 {
        font-weight: 600;
        font-size: 18rem;
        color: #333333;
        line-height: 25rem;
        font-family: PingFangSC-Medium;
        margin-top: -10rem;
      }

      .l3 {
        width: 45rem;
        height: 31rem;
      }

      .l4 {
        font-weight: 600;
        font-size: 22rem;
        color: #4B7CFD;
        line-height: 30rem;
        font-family: PingFangSC-Medium;
        margin-top: 5rem;
      }

      .l5 {
        padding: 6rem 10rem;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 16rem;
        color: #333333;
        line-height: 40rem;
        width: 192rem;
        height: 172rem;
        background: rgba(75, 124, 253, 0.1);
        border-radius: 8rem;
        backdrop-filter: blur(10rem);
        margin-top: 10rem;
      }
    }
  }

  .yycj {
    background-color: #fff;
    padding-top: 60rem;

    .yycj-content {
      margin-top: 80rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &>div {
        width: 1201rem;
        background: #F7F7F7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8rem;

        &>img {
          width: 860rem;
          height: 280rem;
        }

        &>div {
          display: inline-block;
          width: 340rem;
          height: 280rem;
          text-align: left;
          padding: 30rem 22rem;
          box-sizing: border-box;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30rem;
            margin-bottom: 10rem;

            img {
              width: 50rem;
              height: 50rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }

          .content {
            padding: 8rem;
            color: #333333;
            font-size: 16rem;
            line-height: 30rem;
            box-sizing: border-box;
            text-align: justify;
          }
        }
      }

      &>div:last-child {
        margin-top: 10rem;
      }
    }
  }

  .yyal {
    padding-top: 80rem;
    background-color: #fff;
    overflow: hidden;

    .yyal-content {
      margin: 80rem 0 40rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 1040rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60rem;
        padding: 20rem;
        border-radius: 8rem;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(215, 24, 25, 0.2), 0px 0px 4px 0px rgba(215, 24, 25, 0.2);
        }

        img {
          width: 400rem;
          height: 224rem;
        }

        .content {
          width: 607rem;
          height: 224rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 24rem;
            color: #0A0A0A;
            line-height: 33px;
            margin: 8rem 0 9rem;
          }

          .detail {
            font-weight: 400;
            font-size: 14rem;
            color: #333333;
            line-height: 30rem;
            margin-top: 20rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }
  }
}
</style>